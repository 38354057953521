.add-btn-container {
  position: absolute;
  bottom: 0px;
  padding-bottom: 20px;
  width: 100%;
  z-index: 2;
}

ion-list{
  /*height: 100%;*/
  z-index: 1;
}