.fullprice{
    text-decoration:line-through!important;
    color: #c5c2c2;
}

.componible{
    font-weight: bold!important;
}

.selected-product{
    border-left: 8px solid var(--ion-color-primary);

}

.item-count-text{
    font-weight: bold;
    color: var(--ion-color-primary);
    font-size: small;
}

