ion-content ion-toolbar {
    --background: translucent;
  }
  
  .checkout-button {
    padding-left: 20%;
    text-align: center;
    min-width: 50%;
    position: absolute;
    bottom: 20px;
    z-index: 99;
    transition: 1s ease-out 1s slideInFromBottom;
  }
  
  :host(.button-solid.ion-color) .button-native {
    box-shadow: 5px 7px 2px #8080808a !important;
  }
  
  .spacer {
    height: 100px;
  }
  
  .quantityModal .modal-wrapper {
    height: 40%;
    top: 60%;
    position: absolute;
    display: block;
  }
  
  .componentsModal .modal-wrapper {
    height: 95%;
    top: 5%;
    position: absolute;
    display: block;
    border-radius: 12px;
  }
  
  .quantity-button {
    --border-radius: 50%;
    margin-top: 15px;
    height: 40px;
    width: 40px;
  }
  
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateX(20px);
    }
  }