.modal {
  --border-radius: 20px;
  --height: 50%;
  --width: 90%;
}

.cta-button {
  margin: 20px;
}

.image {
  margin-left: auto;
  margin-right: auto;
}


@media (min-width: 768px) {
  .modal {
    --width: 50%;
  }

  .image {
    width: 50%;
  }
}