.item-with-status {
    border-left: 0.5rem;
    border-left-style: solid;
}

.item-with-status.Waiting {
    border-left-color: var(--ion-color-warning);
    animation: border-fade 4s ease-in-out infinite;
}

.item-with-status.Complete {
    border-left-color: var(--ion-color-success);
}

.item-with-status.Cancelled {
    border-left-color: var(--ion-color-danger);
}

@-webkit-keyframes warning-border-fade {
    0% {
        border-left-color: var(--ion-color-warning);
    }

    50% {
        border-left-color: transparent;
    }

    100% {
        border-left-color: var(--ion-color-warning);
    }
}

@keyframes border-fade {
    0% {
        border-left-color: var(--ion-color-warning);
    }

    50% {
        border-left-color: transparent;
    }

    100% {
        border-left-color: var(--ion-color-warning);
    }
}

.order-status-text{
    font-size: small;
}